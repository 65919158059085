import { Link } from "react-router-dom";
import * as constants from "../constants";

function ChosenProduct({ product, onAdd, onRemove, onNav }) {

    const path = `${constants.PRODUCT_PATH}/${product.id}`;

    return (
        <>
            <td className="table-text-column cart-product-name">
                <Link to={path} onClick={() => onNav(path)}>
                    <span title="see product details">{product.productName}</span>
                </Link>
            </td>
            <td className="text-right">
                <button className="decrease-qty-button" onClick={() => onRemove(product)}> - </button>
                &nbsp;/&nbsp;
                <button className="increase-qty-button" onClick={() => onAdd(product)}> + </button>
            </td>
            <td className="table-number-column">{product.qty}</td>
            <td className="table-number-column">${(product.unitPrice * product.qty).toFixed(2)}</td>
        </>
    );
}

export default ChosenProduct;