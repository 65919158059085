import { Link } from "react-router-dom";
import * as constants from "../constants";

import pushbutton from '../static-content/pushbutton.jpg';
function StartPage({ onNav, newPage }) {

    newPage();

    return (
        <>
            <div className="block start-page">
                <Link to={constants.STUFF_PATH} onClick={() => onNav(constants.STUFF_PATH)} className="start-page-text">
                    Let's go shopping! <br /> <img className="pushbuttonimage" src={pushbutton} alt="pushbutton" />
                </Link>
            </div>
        </>
    );
}

export default StartPage;