import Payment from "./Payment";
import Shipping from "./Shipping";

function PurchaseInfo({
    shippingInformation,
    onShippingInformationChange,
    payment,
    onPaymentChange,
}) {
    return (
        <>
            <Payment
                payment={payment}
                onPaymentChange={onPaymentChange}
            />
            <Shipping
                shippingInformation={shippingInformation}
                onShippingInformationChange={onShippingInformationChange}
            />
        </>
    );
}

export default PurchaseInfo;
