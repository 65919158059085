function CartSummary({ cartTotals, emptyCartMessageLine1, emptyCartMessageLine2 }) {

    return (
        <>
            {
                cartTotals.grandTotal.toFixed(2) === "0.00" && (
                    <>
                        <p>
                            <span title="get some stuff">
                                {emptyCartMessageLine1}
                                {emptyCartMessageLine2 && (
                                    <>
                                        <br />
                                        {emptyCartMessageLine2}
                                    </>
                                )}
                            </span>
                        </p>
                    </>
                )
            }
            {
                cartTotals.grandTotal.toFixed(2) !== "0.00" && (
                    <>
                        <p>
                            Your total: <strong>${cartTotals.grandTotal.toFixed(2)}</strong>
                        </p>
                    </>
                )
            }
        </>
    );
}

export default CartSummary;