import logo from '../static-content/dtek-logo-header.jpg';
import cartIcon from '../static-content/shopping-cart.jpg';
import CartSummary from './CartSummary';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import * as constants from "../constants";

function Header({ cartTotals, onNav, navPath }) {

    return (
        <>
            <header className="block">

                <div className="header-left">
                    <a href="https://rob-das-win.azurewebsites.net/about" target='_blank' rel="noreferrer">
                        <img
                            src={logo}
                            width={"65%"}
                            alt="DTEK Consulting Services Ltd."
                            title="DTEK Consulting Services Ltd., Alberta, Canada" />
                    </a>
                </div>

                <div className="header-middle">
                    <div className="header-title">
                        <h1 title="The premier virtual shopping experience!"><span className='app-name-color-header'>DTEK Online Store</span></h1>
                        <p className='header-slogan'>
                            late-night shopping, made easy
                        </p>
                    </div>
                    <div className="header-cart-summary-middle">
                        <CartSummary
                            cartTotals={cartTotals}
                            emptyCartMessageLine1={"Your shopping cart is empty."}
                        />
                    </div>
                </div>

                <div className="header-right">
                    <Link to={constants.CART_PATH} onClick={() => onNav(constants.CART_PATH)}>
                        <img className="pushbuttonimage" src={cartIcon} alt="shopping cart" title="What's in the cart?" />
                    </Link>
                    <CartSummary
                        cartTotals={cartTotals}
                        emptyCartMessageLine1={"Your shopping cart"}
                        emptyCartMessageLine2={"is empty."}
                    />
                </div>

                <Navbar
                    onNav={onNav}
                    navPath={navPath}
                />

            </header>
        </>
    );
}

export default Header;