import { Link } from "react-router-dom";
import * as constants from "../constants";

function Navbar({ onNav, navPath }) {

    return (
        <>
            <nav className="navbar">
                <hr />
                <div className="links">
                    <Link
                        to={constants.STUFF_PATH}
                        onClick={() => onNav(constants.STUFF_PATH)}
                        className={((navPath === constants.STUFF_PATH) ? 'current-page-nav-link' : 'other-page-nav-link')}
                    >
                        <span
                            title="get some more stuff"
                        >
                            Get Stuff
                        </span>
                    </Link>
                    <Link
                        to={constants.CART_PATH}
                        onClick={() => onNav(constants.CART_PATH)}
                        className={((navPath === constants.CART_PATH) ? 'current-page-nav-link' : 'other-page-nav-link')}
                    >
                        <span
                            title="what's in the cart?"
                        >
                            Shopping Cart
                        </span>
                    </Link>
                    <Link
                        to={constants.CHECKOUT_PATH}
                        onClick={() => onNav(constants.CHECKOUT_PATH)}
                        className={((navPath === constants.CHECKOUT_PATH) ? 'current-page-nav-link' : 'other-page-nav-link')}
                    >
                        <span
                            title="it's checkout time"
                        >
                            Checkout
                        </span>
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
