function Shipping({
    shippingInformation,
    onShippingInformationChange,
}) {
    const handleNameChange = (event) => {
        const newName = event.target.value;
        const newShippingInformation = {
            ...shippingInformation,
            customerName: newName
        };
        onShippingInformationChange(newShippingInformation);
    };

    const handleAddressChange = (event) => {
        const newAddress = event.target.value;
        const newShippingInformation = {
            ...shippingInformation,
            address: newAddress
        };
        onShippingInformationChange(newShippingInformation);
    };

    return (
        <>
            <fieldset className="center-element ">
                <legend>Shipping</legend>
                <label htmlFor="cname">name:</label>
                <input
                    id="cname"
                    type="text"
                    value={shippingInformation.customerName}
                    onChange={handleNameChange}
                />
                <br />
                <label htmlFor="caddress">address:</label>
                <textarea
                    id="caddress"
                    rows="4"
                    cols="20"
                    wrap="hard"
                    value={shippingInformation.address}
                    onChange={handleAddressChange}
                />
            </fieldset>
        </>
    );
}

export default Shipping;