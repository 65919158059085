function Totals({ cartTotals }) {

    const { products, tax, grandTotal } = cartTotals;

    return (
        <>
            <tr>
                <td className="table-text-column">subtotal</td>
                <td className="table-number-column">${products.toFixed(2)}</td>
            </tr>
            <tr>
                <td className="table-text-column">tax</td>
                <td className="table-number-column">${tax.toFixed(2)}</td>
            </tr>
            <tr className="text-strong">
                <td className="table-text-column">Total</td>
                <td className="table-number-column">${grandTotal.toFixed(2)}</td>
            </tr>
        </>
    );
}

export default Totals;
