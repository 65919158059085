import { ToastContainer, toast } from "react-toastify";
import Store from "./components/Store";
import { Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const notify = (message) => toast.info(message, { position: "bottom-center", autoClose: 1000, theme: "dark", hideProgressBar: true });
  return (
    <div className="App">
      <Store notify={notify} />
      <ToastContainer position="bottom-center" newestOnTop transition={Zoom} />
    </div>
  );
}

export default App;
