function Payment({
    payment,
    onPaymentChange,
}) {

    const handlePaymentMethodChange = (event) => {
        const newPaymentMethod = event.target.value;
        const newPayment = {
            ...payment,
            paymentMethod: newPaymentMethod,
        };
        onPaymentChange(newPayment);
    };

    const handleCardExpirationDateChange = (event) => {
        const newCardExpirationDate = event.target.value;
        const newPayment = {
            ...payment,
            cardExpirationDate: newCardExpirationDate,
        };
        onPaymentChange(newPayment);
    };

    return (
        <>
            <fieldset className="center-element ">
                <legend>Payment</legend>
                <label htmlFor="method">method:</label>
                <select
                    id="method"
                    value={payment.paymentMethod}
                    onChange={handlePaymentMethodChange}>
                    <option value="">select...</option>
                    <option value="credit card">credit card</option>
                    <option value="paypal">pay pal</option>
                    <option value="mypay">my pay</option>
                </select>
                <br />
                <label htmlFor="cardExpirationDate">expiry:</label>
                <input
                    id="cardExpirationDate"
                    value={payment.cardExpirationDate}
                    type="text"
                    onChange={handleCardExpirationDateChange} />
            </fieldset>
        </>
    );
}

export default Payment;
