import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import ProductList from "./ProductList";
import Cart from "./Cart";
import Checkout from "./Checkout";
import ProductDetails from "./ProductDetails";
import UnknownPage from "./UnknownPage";
import Footer from "./Footer";
import data from "../data";
import { useState, useEffect } from "react";
import StartPage from "./StartPage";
import * as constants from "../constants";

function Store({ notify }) {

    //-----APP DATA-----

    // future enhancement: when moving to a database, this can be replaced with an api call
    const { availableProducts, chosenProducts } = data;

    //-----CONSTANTS-----

    const highlightedHeadingClass = 'highlighted-heading'; // references css file
    const defaultHeadingClass = 'normal-heading'; // references css file
    const delayDefault = 600;

    //-----TEMPLATES-----

    const emptyShippingInformation = {
        customerName: "",
        address: "",
    };
    const emptyPayment = {
        paymentMethod: "",
        cardExpirationDate: "",
    };

    //-----STATE-----

    const [cartContents, setCartContents] = useState(chosenProducts);
    const [cartTotals, setCartTotals] = useState({ products: 0.00, tax: 0.00, grandTotal: 0.00 });
    const [shippingInformation, setShippingInformation] = useState(emptyShippingInformation);
    const [payment, setPayment] = useState(emptyPayment);
    const [headingClass, setHeadingClass] = useState(defaultHeadingClass);
    const [navPath, setNavPath] = useState(constants.START_PATH);

    //-----FUNCTIONS TO ACCESS STATE-----

    // onNav 
    // When nav buttons are clicked for the page the user is already on, provide feedback by highlighting the page heading.
    // The most recent nav  (ie. the current page) is saved in the navPath state variable in this component. Since navigation 
    // can occur in a number of ways, including as a side effect, onNav only manages the highlighting but not the navigation itself. 
    // Therefore onNav must be called by any component that causes navigation. 
    // TODO: Consider possible rewrite with useLocation(), see https://reactrouter.com/en/main/hooks/use-location
    const onNav = (path) => {
        const isChanged = (path !== navPath);
        setNavPath(path);
        window.scrollTo(0, 0);
        if (!isChanged) {
            setHeadingClass(highlightedHeadingClass);
            setTimeout(() => {
                setHeadingClass(defaultHeadingClass);
            }, delayDefault);
        }
    };

    const clearCart = () => {
        setCartContents([]);
        setShippingInformation(emptyShippingInformation);
        setPayment(emptyPayment);
    };

    const onAdd = (product) => {
        const exists = cartContents.find((x) => x.id === product.id);
        if (exists) {
            setCartContents(
                cartContents.map((x) =>
                    x.id === product.id ? { ...exists, qty: exists.qty + 1 } : x
                )
            );
        } else {
            const newProduct = {
                "id": product.id,
                "productName": product.productName,
                "productDetails": product.productDetails,
                "unitPrice": product.unitPrice,
                "qty": 1
            };
            setCartContents([...cartContents, newProduct]);
        }
    };

    const onRemove = (product) => {
        const exists = cartContents.find((x) => x.id === product.id);
        if (exists && exists.qty === 1) {
            setCartContents(cartContents.filter((x) => x.id !== product.id));
        } else {
            setCartContents(
                cartContents.map((x) =>
                    x.id === product.id ? { ...exists, qty: exists.qty - 1 } : x
                )
            );
        }
    };

    useEffect(() => {
        let productsPrice = 0.00;
        let taxPrice = 0.00;
        let totalPrice = 0.00;

        if (cartContents.length !== 0) {
            productsPrice = cartContents.reduce((acc, cur) => { return acc + (cur.qty * cur.unitPrice) }, 0);
            taxPrice = productsPrice * constants.TAX_RATE;
            totalPrice = productsPrice + taxPrice;
        }

        setCartTotals({ products: productsPrice, tax: taxPrice, grandTotal: totalPrice });
        if (totalPrice > 0.00) {
            notify(`Your total is  ${totalPrice.toFixed(2)}`);
        }
    }, [cartContents, notify]);

    return (
        <>
            <div className="site-boundary center-element">
                <BrowserRouter>
                    <Header
                        cartTotals={cartTotals}
                        onNav={onNav}
                        navPath={navPath}
                    />
                    <main className="block">
                        <Routes>
                            <Route
                                path={constants.START_PATH}
                                element={
                                    <StartPage
                                        onNav={onNav}
                                        newPage={() => { setNavPath(constants.START_PATH); }}
                                    />
                                }
                            />
                            <Route
                                path={constants.STUFF_PATH}
                                element={
                                    <ProductList
                                        availableProducts={availableProducts}
                                        onNav={onNav}
                                        headingClass={headingClass}
                                        newPage={() => { setNavPath(constants.STUFF_PATH); }}
                                    />
                                }
                            />
                            <Route
                                path={constants.CART_PATH}
                                element={
                                    <Cart
                                        cartContents={cartContents}
                                        onAdd={onAdd}
                                        onRemove={onRemove}
                                        clearCart={clearCart}
                                        headingClass={headingClass}
                                        onNav={onNav}
                                        cartTotals={cartTotals}
                                        newPage={() => { setNavPath(constants.CART_PATH); }}
                                    />
                                }
                            />
                            <Route
                                path={constants.CHECKOUT_PATH}
                                element={
                                    <Checkout
                                        shippingInformation={shippingInformation}
                                        onShippingInformationChange={setShippingInformation}
                                        payment={payment}
                                        onPaymentChange={setPayment}
                                        clearCart={clearCart}
                                        notify={notify}
                                        headingClass={headingClass}
                                        onNav={onNav}
                                        cartTotals={cartTotals}
                                        newPage={() => { setNavPath(constants.CHECKOUT_PATH); }}
                                    />
                                }
                            />
                            <Route
                                path={`${constants.PRODUCT_PATH}/:id`}
                                element={
                                    <ProductDetails
                                        availableProducts={availableProducts}
                                        onAdd={onAdd}
                                        newPage={() => { setNavPath(constants.PRODUCT_PATH); }}
                                    />
                                }
                            />
                            <Route path="*" element={<UnknownPage />} />
                        </Routes>
                    </main>
                </BrowserRouter>
                <Footer />
            </div>
        </>
    );
}

export default Store;