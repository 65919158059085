import ChosenProduct from "./ChosenProduct";

function ChosenProductList({ cartContents, onAdd, onRemove, onNav }) {

    return (
        <>
            {cartContents.map((product) => (
                <tr key={product.id}>
                    <ChosenProduct
                        product={product}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onNav={onNav} />
                </tr>
            ))}
        </>
    );
}

export default ChosenProductList;
