import { Link, useNavigate } from "react-router-dom";
import * as constants from "../constants";

function ProductSummary({ product, onNav }) {
    const toProductDetail = `${constants.PRODUCT_PATH}/${product.id}`;
    const title = `want to know more about ${product.productName}?`;
    const navigate = useNavigate();
    return (
        <>
            <div className="product-summary">
                <h3>{product.productName}</h3>
                <div>
                    <Link to={toProductDetail} onClick={() => onNav(toProductDetail)}>
                        <img
                            src={product.productImageFile}
                            alt={product.productName}
                            className="summary-image" />
                    </Link>
                </div>
                <button
                    onClick={() => { onNav(toProductDetail); navigate(toProductDetail); }}
                    title={title} >
                    details
                </button>
            </div>
        </>
    );
}

export default ProductSummary;