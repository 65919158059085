import { useNavigate } from "react-router-dom";
import ChosenProductList from "./ChosenProductList";
import Totals from "./Totals";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as constants from "../constants";

function Cart({ cartContents, onAdd, onRemove, clearCart, headingClass, onNav, cartTotals, newPage }) {

    newPage();

    const navigate = useNavigate();
    const gotoCheckout = () => {
        onNav(constants.CHECKOUT_PATH);
        navigate(constants.CHECKOUT_PATH);
    }
    const gotoStart = () => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure you want to start again? You will abandon your stuff, your total will be set to zero, and you will get a new empty shopping cart.',
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {
                        clearCart();
                        onNav(constants.START_PATH);
                        navigate(constants.START_PATH);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                        ;
                    }
                }
            ]
        });

    }

    return (
        <>
            <h2><span className={headingClass}>Shopping Cart</span></h2>
            <table className="center-element shopping-cart-width product-list-table">
                {cartContents.length > 0 &&
                    <thead>
                        <tr>
                            <th className="table-column-heading table-text-column">your stuff</th>
                            <th className="table-column-heading text-right"> remove / add </th>
                            <th className="table-column-heading table-number-column">qty</th>
                            <th className="table-column-heading table-number-column">cost</th>
                        </tr>
                    </thead>
                }
                <tbody>
                    <ChosenProductList
                        cartContents={cartContents}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onNav={onNav} />
                </tbody>
            </table>

            <table className="center-element shopping-cart-width solid-top solid-bottom totals-table">
                <tbody>
                    <Totals
                        cartTotals={cartTotals}
                    />
                </tbody>
            </table>

            <div className="center-element shopping-cart-width text-left">
                {
                    (cartTotals.grandTotal.toFixed(2) === "0.00")
                        ?
                        <p>
                            <span className="error-text-color">***<em>Shopping cart is empty. You need some stuff.</em></span>
                            <br /> <br />
                        </p>
                        :
                        <>
                            <br /> <br /> <br />
                        </>
                }
            </div>


            <div className="center-element shopping-cart-width text-right">
                <button className="start-again-button" title="abandon your stuff and start again with a new empty shopping cart" onClick={() => gotoStart()}>start again</button>
                <button className="checkout-button" title="it's checkout time" onClick={() => gotoCheckout()}>checkout</button>
            </div>
        </>
    );
}

export default Cart;