import { useNavigate } from "react-router-dom";
import PurchaseInfo from "./PurchaseInfo";
import Totals from "./Totals";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import './Checkout.css';
import * as constants from "../constants";

function Checkout({
    shippingInformation,
    onShippingInformationChange,
    payment,
    onPaymentChange,
    clearCart,
    notify,
    headingClass,
    onNav,
    cartTotals,
    newPage,
}) {

    newPage();

    //-----STATE-----

    const [showPaymentNotAcceptedAlert, setShowPaymentNotAcceptedAlert] = useState(false);
    const [paymentDisabled, setPaymentDisabled] = useState(false);

    //-----FUNCTIONS TO ACCESS STATE-----

    const missingInfo = () => {
        if (shippingInformation.customerName &&
            shippingInformation.address &&
            payment.paymentMethod &&
            payment.cardExpirationDate
        ) {
            return false;
        }
        else {
            return true;
        }
    };

    const navigate = useNavigate();

    const onPay = () => {
        if (missingInfo()) {
            notify("missing information");
            return;
        }
        clearCart();
        setPaymentDisabled(true);
        notify("payment not accepted");
        setShowPaymentNotAcceptedAlert(true);
    }

    return (
        <>
            <h2><span className={headingClass}>Checkout</span></h2>
            {
                (showPaymentNotAcceptedAlert)
                    ?
                    <div className='center-element oops-alert checkout-width'>
                        <Alert variant="primary" onClose={() => navigate(constants.START_PATH)}>
                            <Alert.Heading>Payment Not Accepted</Alert.Heading>
                            <p>
                                We're sorry, the payment system is not yet online.
                                Your shopping cart has been cleared, and your information has not been saved.
                                Please try again later.
                            </p>
                            <div className="text-center">
                                <button
                                    className="ok-button"
                                    onClick={
                                        () => {
                                            onNav(constants.START_PATH);
                                            navigate(constants.START_PATH);
                                        }
                                    }
                                >
                                    OK
                                </button>
                            </div>
                        </Alert>
                    </div>
                    :
                    <></>
            }
            <div className="center-element checkout-width text-right">
                <button
                    className="pay-button center-element"
                    onClick={() => onPay()}
                    disabled={paymentDisabled || (cartTotals.grandTotal.toFixed(2) === "0.00") || missingInfo()}
                    title={(paymentDisabled || (cartTotals.grandTotal.toFixed(2) === "0.00") || missingInfo()) ? "" : "press this button to make your final purchase"}
                >
                    purchase
                </button>
            </div>
            <table className="center-element checkout-width solid-top solid-bottom totals-table">
                <tbody>
                    <Totals
                        cartTotals={cartTotals}
                    />
                </tbody>
            </table>
            <div className="center-element checkout-width text-left">
                {
                    (!paymentDisabled && cartTotals.grandTotal.toFixed(2) === "0.00")
                        ?
                        <p>
                            <span className="error-text-color">***<em>Shopping cart is empty. You need some stuff.</em></span>
                            <br /> <br />
                        </p>
                        :
                        (
                            (!paymentDisabled && missingInfo())
                                ?
                                <p>
                                    <br /> <br />
                                    <span className="error-text-color">***<em>All Payment and Shipping info is required.</em></span>
                                </p>
                                :
                                <>
                                    <br /> <br /> <br />
                                </>
                        )
                }
            </div>
            <PurchaseInfo
                shippingInformation={shippingInformation}
                onShippingInformationChange={onShippingInformationChange}
                payment={payment}
                onPaymentChange={onPaymentChange}
            />
        </>
    );
}

export default Checkout;