import logo from '../static-content/dtek-logo-small.jpg';

function Footer() {
    return (
        <>
            <footer className='block'>
                <div>
                    <small>
                        <div>
                            <div className="footer-promo">
                                &nbsp; <br />
                                <a href="https://rob-das-win.azurewebsites.net/about" target='_blank' rel="noreferrer">
                                    <img
                                        src={logo}
                                        alt="DTEK Consulting Services Ltd."
                                        title="DTEK Consulting Services Ltd., Alberta, Canada" />
                                </a>
                            </div>
                            <div className="footer-promo footer-text-alignment footer-promo-text">
                                <p>
                                    <span className='app-name-color-footer'>DTEK Online Store</span> v1.1.1 &mdash; a React
                                    development, proof-of-concept.
                                    This simulated eCommerce site is intended to provide a recreational shopping experience.
                                </p>
                                <p>
                                    Copyright &copy; Robi Indra Das, 2009-{(new window.Date()).getFullYear()}. All rights reserved.
                                    DTEK Consulting Services Ltd. is a Canadian company.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="footer-text-alignment footer-disclaimer-text">
                                <p>
                                    This site is not intended for buying or selling anything. Nothing changes hands.
                                    It's a simulation of a user's shopping experience, without concern for payment
                                    or shipping - just a bottomless shopping cart with an unlimited grand total
                                    that never actually gets paid.
                                    It's therapeutic - try it, you might like it.
                                </p>
                                <p>
                                    In the context of this site,
                                    "<em>Stuff</em>" are just images of real things. They're based on royalty free, public domain pics
                                    from <a href="https://www.pexels.com/" target='_blank' rel="noreferrer">Pexels</a>.
                                </p>
                            </div>
                        </div>
                    </small>
                </div>
            </footer>
        </>
    );
}

export default Footer;