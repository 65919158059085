import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ProductDetails({ availableProducts, onAdd, newPage }) {

    newPage();

    const { id } = useParams();
    const product = availableProducts.find((element) => element.id === id);
    const navigate = useNavigate();
    const gotoPrevious = () => {
        navigate(-1);
    }


    useEffect(() => {
        const notFound = (product === undefined);
        if (notFound) {
            navigate("/oops");
        }
    }, [product, navigate]);

    return (
        <>
            <h2>{product.productName} </h2>
            <p>
                {product.productDetails}
            </p>
            <p>
                <small>${product.unitPrice.toFixed(2)} + tax</small>
            </p>
            <button
                className="buy-button"
                title="Gimme!"
                onClick={
                    () => {
                        onAdd(product);
                        gotoPrevious();
                    }
                }
            >
                Add to Cart
            </button>
            <button
                className="buy-button"
                title="Not now."
                onClick={
                    () => {
                        gotoPrevious();
                    }
                }
            >
                Back
            </button>
            <div>
                <img
                    src={product.productImageFile}
                    alt={product.productName}
                    className="detail-image" />
            </div>
        </>
    );
}

export default ProductDetails;
