import ProductSummary from "./ProductSummary";

function ProductList({ availableProducts, onNav, headingClass, newPage }) {

    newPage();

    return (
        <>
            <h2><span className={headingClass}>Get Stuff</span></h2>
            <div className="center-element flex-container-image product-list-width">

                {availableProducts.map((item) => (
                    <div key={item.id}>
                        <ProductSummary product={item} onNav={onNav} />
                    </div>
                ))}
            </div>

        </>
    );
}

export default ProductList;